import Link from "next/link";
import useMobileAppStore from "../lib/hooks/useMobileAppStore";
import ImageSourceSet from "../types/ImageSourceSet";
import LazyLoadedImg from "./LazyLoadedImg";

type CategoryCardProps = {
  name: string;
  id: string;
  backgroundColor?: string;
  textColor?: string;
  viewCount?: number;
  size?: "small" | "card";
  srcSet?: ImageSourceSet;
};

const CategoryCard: React.FC<CategoryCardProps> = ({ name, id, backgroundColor = "", textColor = "", viewCount = "", size = "card", srcSet }) => {
  const { isMobileAppView } = useMobileAppStore();
  if (size === "card") {
    return (
      <Link href={`/categories/${id}`}>
        <div
          className={`group flex-none border-solid ${
            isMobileAppView ? "w-52 h-72" : "w-72 h-80"
          } border-2 border-transparent flex transition duration-[250ms] ease-in-out ${
            !isMobileAppView && "hover:border-white hover:transition hover:scale-[103%]"
          } rounded-xl overflow-hidden cursor-pointer`}
        >
          <div className="relative flex justify-center items-center rounded-xl h-full w-full">
            {srcSet && (
              <>
                <LazyLoadedImg className="w-full h-full rounded-xl absolute" srcSet={srcSet ?? {}} />
              </>
            )}
            <p className="transition duration-[250ms] ease-in-out-transform z-50 drop-shadow-category-card-text text-lg">{name}</p>
            <div
              className={`w-full h-full absolute z-40 bg-gray-900 bg-opacity-0  transition duration-[250ms] ease-in-out shadow-category-card-inner  ${
                !isMobileAppView &&
                "group-hover:backdrop-saturate-100 backdrop-blur-xs group-hover:bg-opacity-0 group-hover:backdrop-blur-none group-hover:transition group-hover:shadow-none"
              } rounded-lg`}
            ></div>
          </div>
        </div>
      </Link>
    );
  } else if (size === "small") {
    return (
      <Link href={`/categories/${id}`}>
        <div
          className={`group flex transition ease-in-out-transform duration-[250ms] ease-in-out-transform h-24 rounded-lg overflow-hidden cursor-pointer ${
            !isMobileAppView && "hover:border-solid hover:transition hover:border-white hover:border-2 hover:scale-[103%]"
          }`}
        >
          <div className="relative flex justify-center items-center h-full w-full">
            {srcSet && (
              <>
                <LazyLoadedImg className="rounded-lg w-full h-full absolute" srcSet={srcSet ?? {}} />
              </>
            )}
            <p className="transition duration-[250ms] ease-in-out-transform z-50 drop-shadow-category-card-text text-[2rem]">{name}</p>
            <div
              className={`w-full h-full absolute z-40 bg-gray-900 bg-opacity-0 transition duration-[250ms] ease-in-out shadow-category-card-inner rounded-lg ${
                !isMobileAppView && "backdrop-blur-xs group-hover:backdrop-blur-none group-hover:transition group-hover:shadow-none"
              }`}
            ></div>
          </div>
        </div>
      </Link>
    );
  } else {
    return <div></div>;
  }
};

export default CategoryCard;
