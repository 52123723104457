import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import AppProviders from "../components/AppProviders";
import AlertRoot from "../components/context/AlertRoot";
import ModalRoot from "../components/context/ModalRoot";
import CookieConsent from "../components/CookieConsent";
import PageGuard from "../components/PageGuard";
import useEffectOnceAllDeps from "../lib/hooks/useEffectOnceAllDeps";
import "../styles/globals.css";
import CustomNextPage from "../types/CustomNextPage";
import { themeColor } from "../lib/constants";
import Seo from "../components/Seo";
import { appWithTranslation } from "next-i18next";
import nextI18nConfig from "../../next-i18next.config.js";
import useMobileAppStore from "../lib/hooks/useMobileAppStore";

const EmptyLayout = ({ children, layoutProps }: { children: React.ReactNode; layoutProps: unknown }) => <>{children}</>;

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  // on load, if app query param is present, save it to local storage
  const { setIsMobileAppView, isMobileAppView } = useMobileAppStore();

  useEffect(() => {
    if (typeof router.query.app !== "undefined") {
      localStorage.setItem("app", "true");
      setIsMobileAppView(true);
    }
  }, [router.query.app, setIsMobileAppView]);

  if (typeof window !== "undefined") {
    if (!isMobileAppView && localStorage.getItem("app")) {
      setIsMobileAppView(true);
    }
  }

  const CustomPageComponent = Component as CustomNextPage<unknown>;

  const Layout = CustomPageComponent.layout ?? EmptyLayout;
  const [layoutProps, setLayoutProps_] = useState<any>(null);
  const setLayoutProps = (value: any) => {
    if (layoutProps === null) setLayoutProps_(value);
  };

  const prevRoute = useRef<string | null>(null);

  useEffectOnceAllDeps(() => {
    const onRouteChange = (url: string) => {
      if (prevRoute.current !== url) {
        prevRoute.current = url;
        setLayoutProps_(null);
      }
    };

    router.events.on("routeChangeStart", onRouteChange);

    return () => router.events.off("routeChangeStart", onRouteChange);
  }, [router.events]);

  const mobileAppSafeAreaStyles = {
    headerStyles: {
      /* minHeight: "env(safe-area-inset-top)" */
    },
    contentStyles: {
      /*
      overflow: "auto",
      paddingLeft: "env(safe-area-inset-left)",
      paddingRight: "env(safe-area-inset-right)",
  */
    },
    footerStyles: {
      /* minHeight: "env(safe-area-inset-bottom)" */
    },
  };

  return (
    <>
      {isMobileAppView && (
        <style jsx global>{`
          ::-webkit-scrollbar {
            display: none;
          }
          html {
            overflow: hidden;
          }
        `}</style>
      )}
      <header style={mobileAppSafeAreaStyles.headerStyles}></header>
      <main style={isMobileAppView ? mobileAppSafeAreaStyles.contentStyles : {}} className={`h-screen bg-gray-900 overflow-y-auto overflow-x-hidden`}>
        <Seo />
        <Head>
          <meta name="theme-color" content={themeColor} />
          <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover" />
        </Head>
        <AppProviders page={CustomPageComponent} pageProps={pageProps}>
          <PageGuard page={CustomPageComponent}>
            <Layout layoutProps={layoutProps}>
              <CustomPageComponent {...pageProps} setLayoutProps={setLayoutProps} />
              <CookieConsent />
              <ModalRoot />
              <AlertRoot />
            </Layout>
          </PageGuard>
        </AppProviders>
      </main>
      <footer style={mobileAppSafeAreaStyles.footerStyles}></footer>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18nConfig);
